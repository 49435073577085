import React, { useEffect } from 'react';
import './App.css';
import logo from './logo.svg'; // 로고 이미지 가져오기
import "@fontsource/jetbrains-mono/700.css";
import "@fontsource/pretendard"

function App() {
  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  useEffect(() => {
    if (isMobileDevice()) {
      window.location.href = 'https://m.crafter.re.kr';
    }
  }, []);

  useEffect(() => {
    document.title = "CRAFTER";
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = logo;
    document.getElementsByTagName('head')[0].appendChild(link);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container" onClick={() => scrollToSection('section1')}>
          <img src={logo} alt="Logo" className="App-logo" />
          <span className="logot">CRAFTER</span>
        </div>
        <nav>
          <ul>
            <li onClick={() => scrollToSection('section1')}>About CRAFTER</li>
            <li onClick={() => scrollToSection('section2')}>What do CRAFTER do?</li>
            <li onClick={() => scrollToSection('section3')}>Section 3</li>
            <li onClick={() => scrollToSection('section4')}>Section 4</li>
          </ul>
        </nav>
      </header>
      <div className="sections">
        <section id="section1">Section 1 Content</section>
        <section id="section2">Section 2 Content</section>
        <section id="section3">Section 3 Content</section>
        <section id="section4">Section 4 Content</section>
        <section id="section5">
          <h2>Contacts</h2>
        </section>
      </div>
    </div>
  );
}

export default App;